<template>
    <div id="PopupRequests" class="loading-wrapper" v-if="isActive">
        <Loader id="page-loader" v-if="loading"></Loader>
        <div :class="'loading-popup popup-' + type">
            <div class="h-100 m-5 align-items-center text-center m-0">
                <div class="row">
                    <div class="col align-items-center">
                        <!-- <div class="float-left">
                            <img v-if="type == 'success'" class="icon" :src="require('@/assets/images/check-circle.png')" />
                            <img v-if="type == 'danger'" class="icon" :src="require('@/assets/images/danger.png')" />
                        </div> -->
                        <div class="closeDelay" @click="closeDelayPopup">
                            <font-awesome-icon class="times" :icon="['fas', 'times']" />
                        </div>
                        
                        <div class="text-left d-inline-block w-100">
                            <h2 class="popup-title" style="margin: 0 30px;">{{title}}</h2>
                            <span class="message">{{message}}</span>
                            <hr>
                            <div class="requests" style="max-height: 40vh;overflow-y: scroll;"  v-if="requests.length>0">
                                <div :class="'request request-'+request.id" v-for="request in requests" :key="request.id">
                                    <span class="name">{{ request.fname }} {{ request.lname }}</span>
                                    <span class="phone">
                                        <font-awesome-icon class="faPhone" :icon="['fas', 'phone']" />
                                        {{ request.phone_number }}
                                    </span>
                                    <span class="email">
                                        <font-awesome-icon class="envelope" :icon="['fas', 'envelope']" />
                                        {{ request.email }}
                                    </span>
                                    <span class="time">
                                        <font-awesome-icon class="clock" :icon="['fas', 'clock']" />
                                        {{ request.from_now }}
                                    </span>
                                    <div class="services">
                                        <span class="badge badge-info" v-for="service in request.services" :key="service"> {{ service }}</span>
                                    </div>
                                    <div class="buttons">
                                        <button class="decline btn btn-danger" @click="decline(request)">
                                            <font-awesome-icon class="times" :icon="['fas', 'times']" />
                                        </button>
                                        <button class="approve btn btn-success" @click="approve(request)">
                                            <font-awesome-icon class="check" :icon="['fas', 'check']" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="requests" style="max-height: 50vh;overflow-y: scroll;text-align: center;" v-else>
                                <img  :src="require('@/assets/images/2039107.png')" alt=""  style="width: 15vh;padding: 10px;"/>
                                <p style="font-size: 3vh;">{{$t('Teller.NoRequests')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { queueRequestService } from '../../_services'
    import Loader from '../loader/Loader.vue'
    import $ from 'jquery'

    export default {
        name: 'LoadingPopup',
        data:()=>({
            loading: false
        }),
        props: {
            active: Boolean,
            title: String,
            message: String,
            hint: String,
            type: String,
            confirmationButton: String,
            requests: Array
        },
        methods: {
            approve(request){
                const self= this
                this.loading= true
                queueRequestService.approve(request).then(function(){
                }).finally(function(){
                    self.loading= false
                    $('.request-'+request.id).hide(500)
                })
            },
            decline(request){
                const self= this
                queueRequestService.decline(request).then(function(){
                }).finally(function(){
                    self.loading= false
                    $('.request-'+request.id).hide(500)
                })
            },
            closeDelayPopup(){
                this.$emit('close')
            }
        },
        computed: {
            isActive(){
                return this.active;
            }
        },
        components: {
            Loader
        }
    }
</script>