<template>
  <div id="window" class="p-0">
    <div class="window-component p-0">
      <div class="row top-row p-0 m-0">
        <div class="col-2 p-0 close" >
            
          <select name="language" id="language-selector" @change="changeLanguage(lang)" v-model="lang">
            <option v-for="(item, index) in langOptions" :key="index" :value="item.value">{{item.text}}</option>
          </select>
        </div>
        
        <div class="col-7"></div>
        <div class="col-3 p-0 close">
          <button @click="closeButton">
            <img
              class="icon"
              :src="require('@/assets/images/close-blue.png')"
              alt=""
            />
            <span>{{$t('Teller.Close')}}</span>
          </button>
        </div>
      </div>
      <div class="row mt-4" style="padding: 0 20px;" v-if="landscape">
        <div class="col-3 box">
          <div class="box-delay" @click="logout">
            <img
              class="icon"
              :src="require('@/assets/images/1286907.png')"
              alt=""
            /><br />
            <span> {{$t('Teller.LogoutButton')}} </span>
          </div>
        </div>
        <div class="col-3 box">
          <div class="box-delay" @click="backToQueues">
            <img
              class="icon"
              :src="require('@/assets/images/945362.png')"
              alt=""
            /><br />
            <span> {{$t('Teller.Queues')}} </span>
          </div>
        </div>
        <div class="col-3 box" v-if="delay == null">
          <div class="box-delay" @click="addDelay">
            <img
              class="icon"
              :src="require('@/assets/images/2972543.png')"
              alt=""
            /><br />
            <span> {{ $t('Teller.AddLate') }} </span>
          </div>
        </div>
        <div class="col-3 box" v-else>
          <div class="box-delay" @click="resumeDelay">
            <img
              class="icon"
              :src="require('@/assets/images/2377793.png')"
              alt=""
            /><br />
            <span> {{$t('Teller.ComeBack')}} </span>
          </div>
        </div>
        <div class="col-3 box">
          <div class="box-delay" @click="pendingRequests">
            <img
              class="icon"
              :src="require('@/assets/images/1056488.png')"
              alt=""
            /><br />
            <span> {{$t('Teller.Requests')}} </span>
          </div>
        </div>
      </div>

      <div class="row mt-4" style="padding: 0 20px;" v-else>
        <div class="col-6 box" style="height: 30vh;margin-bottom: 3vh;">
          <div class="box-delay" @click="logout">
            <img
              class="icon"
              :src="require('@/assets/images/1286907.png')"
              alt=""
            /><br />
            <span> {{$t('Teller.LogoutButton')}} </span>
          </div>
        </div>
        <div class="col-6 box" style="height: 30vh;margin-bottom: 3vh;">
          <div class="box-delay" @click="backToQueues">
            <img
              class="icon"
              :src="require('@/assets/images/945362.png')"
              alt=""
            /><br />
            <span> {{$t('Teller.Queues')}} </span>
          </div>
        </div>
        <div
          class="col-6 box"
          style="height: 30vh;margin-bottom: 3vh;"
          v-if="delay == null"
        >
          <div class="box-delay" @click="addDelay">
            <img
              class="icon"
              :src="require('@/assets/images/2972543.png')"
              alt=""
            /><br />
            <span> {{$t('Teller.AddLate')}} </span>
          </div>
        </div>
        <div class="col-6 box" style="height: 30vh;margin-bottom: 3vh;" v-else>
          <div class="box-delay" @click="resumeDelay">
            <img
              class="icon"
              :src="require('@/assets/images/2377793.png')"
              alt=""
            /><br />
            <span> {{$t('Teller.ComeBack')}} </span>
          </div>
        </div>
        <div class="col-6 box" style="height: 30vh;margin-bottom: 3vh;">
          <div class="box-delay" @click="pendingRequests">
            <img
              class="icon"
              :src="require('@/assets/images/1056488.png')"
              alt=""
            /><br />
            <span> {{$t('Teller.Requests')}} </span>
          </div>
        </div>
      </div>
    </div>

    <PopupDelay
      :message="popup.message"
      :hint="popup.hint"
      :title="popup.title"
      :type="popup.type"
      :confirmationButton="popup.confirmation"
      :active.sync="popup.active"
      @confirm="popup.callback ? popup.callback : hidePopup()"
      @close="hidePopup()"
    >
    </PopupDelay>
    <PopupRequests
      :requests="requests"
      :message="popup_requests.message"
      :hint="popup_requests.hint"
      :title="popup_requests.title"
      :type="popup_requests.type"
      :confirmationButton="popup_requests.confirmation"
      :active.sync="popup_requests.active"
      @confirm="
        popup_requests.callback ? popup_requests.callback : hidePopupRequests()
      "
      @close="hidePopupRequests()"
    >
    </PopupRequests>
  </div>
</template>

<script>
import $ from "jquery";
import PopupDelay from "../../components/popups/PopupDelay.vue";
// import PopupAddDelay from '../../components/popups/PopupAddDelay.vue';
import PopupRequests from "../../components/popups/PopupRequests.vue";
import { userService } from "../../_services";

export default {
  name: "Window",
  data: function () {
    return {
    counter: "",
    lang: "",
    popup: {
      active: false,
      title: "",
      message: "",
      hint: "",
      type: "",
      confirmation: "",
      callback: null,
    },
    popup_requests: {
      active: false,
      title: "",
      message: "",
      hint: "",
      type: "",
      confirmation: "",
      callback: null,
    },
    landscape: false,
    langOptions: [
          { text: this.$i18n.t('Teller.Arabic'), value: 'ar' },
          { text: this.$i18n.t('Teller.English'), value: 'en' },
          { text: this.$i18n.t('Teller.French'), value: 'fr' }
       ],
  };},
  props: {
    delay: Object,
    requests: Array,
  },
  methods: {
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale == "en")
       { this.lang = "en" }
      else if (locale == "fr") 
      { this.lang = "fr" }
      else { 
        this.lang = "ar" }
    },
    closeButton() {
      $(".window-component").slideUp("500", function() {
        $(".footer").show();
      });
    },
    UpdateHeights() {
      this.landscape = $(window).width() > $(window).height();

      $(".window-component").height($(window).height());
      $(".top-row").height($(window).height() * 0.15);
    },
    closeCounter() {
      this.counter = "";
    },
    logout() {
      userService.logout().then(() => {
        this.$router.push("/login");
      });
    },

    //Add delay popup
    addDelay() {
      this.showPopup(
        "success",
        this.$t('Teller.AddLate'),
        "",
        "",
        this.$t('Teller.AddLate'),
        this.hidePopup
      );
    },
    resumeDelay() {
      this.$emit("resumeDelay");
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('Language', locale);
      location.reload(true);
    },
    showPopup(type, title, message, hint, confirmation, callback) {
      this.popup = {
        active: true,
        title: title,
        message: message,
        hint: hint,
        type: type,
        confirmation: confirmation,
        callback: callback,
      };
    },
    hidePopup() {
      this.popup = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
    },

    //Pending requests popup
    pendingRequests() {
      this.showPopupRequests(
        "success",
        this.$t('Teller.Requests'),
        "",
        "",
        this.$t('Teller.Requests'),
        this.hidePopupRequests
      );
    },
    showPopupRequests(type, title, message, hint, confirmation, callback) {
      this.popup_requests = {
        active: true,
        title: title,
        message: message,
        hint: hint,
        type: type,
        confirmation: confirmation,
        callback: callback,
      };
    },
    hidePopupRequests() {
      this.popup_requests = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
    },

    backToQueues() {
      window.location.href = "/waitinglist";
      // this.$emit('backToQueues')
    },
  },
  mounted() {
    this.UpdateHeights();
    this.getCurrentLanguage()
  },
  created() {
    window.addEventListener("resize", this.UpdateHeights);
    
  },
  components: {
    PopupDelay,
    //     PopupAddDelay,
    PopupRequests,
  },
};
</script>

<style scoped>
#window {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.window-component {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  display: none;
}
.top-row {
  background-color: #edfaff;
  width: 100%;
  height: 60px;
}
.close button {
  background-color: #d6f3ff;
  color: #29a1d3;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 3.5vw;
}
.close select {
  background-color: #d6f3ff;
  color: #29a1d3;
  width: 100%;
  padding-left: 30px;
  height: 100%;
  border: none;
  font-size: 3vw;
}
.close span {
  font-weight: normal;
  display: inline-block;
  margin-left: 15px;
}
.close .icon {
  display: inline-block;
  width: 3.5vw;
  position: relative;
  margin-top: -7px;
}
.box {
  display: table;
  height: 70vh;
  cursor: pointer;
}
.box-delay {
  background-color: #d6f3ff;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  transition: 0.3s;
  color: #29a1d3;
}
.box-delay:hover {
  background-color: #edfaff;
}
.box-delay .icon {
  margin-bottom: 10px;
  width: 7vh;
}
.box-delay span {
  font-size: 3vh;
}
</style>
